"use client";
import { PropsWithChildren } from "react";
import { FeatureFlagProvider } from "@xenia-apps/resident-app/lib/feature-flags/feature-flag-provider";
import { ToastProvider } from "@xenia-libs/xenia-ui/toast";
import { XeniaUiProvider } from "@xenia-libs/xenia-ui/providers/xenia-ui-provider";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import {
  AbstractIntlMessages,
  NextIntlClientProvider,
} from "@xenia-libs/next-i18n";
import { SupportedLocales } from "@xenia-libs/resident-app-i18n/supported-locales";
import {
  Brand,
  ColorwayName,
} from "@xenia-libs/resident-app-data/graphql/platapi/generated";
import { ApolloNextAppProvider } from "@apollo/experimental-nextjs-app-support/ssr";
import { makeApolloClient } from "@xenia-libs/resident-app-data/apollo-client/make-apollo-client";
import { ZodErrorMap } from "./zod-error-map";

export type ProvidersProps = PropsWithChildren<{
  locale: SupportedLocales;
  messages: AbstractIntlMessages;
  brand: Brand;
  colorway: ColorwayName;
}>;

export function Providers({
  children,
  locale,
  messages,
  brand,
  colorway,
}: ProvidersProps) {
  return (
    <AppRouterCacheProvider>
      <NextIntlClientProvider locale={locale} messages={messages}>
        <FeatureFlagProvider>
          <ApolloNextAppProvider makeClient={makeApolloClient}>
            <XeniaUiProvider
              themeProps={{ theme: brand.theme, colorwayName: colorway }}
            >
              <ToastProvider>
                <ZodErrorMap>{children}</ZodErrorMap>
              </ToastProvider>
            </XeniaUiProvider>
          </ApolloNextAppProvider>
        </FeatureFlagProvider>
      </NextIntlClientProvider>
    </AppRouterCacheProvider>
  );
}
