import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/xenia/xenia/apps/resident-app/src/app/[locale]/[configId]/components/analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientBody"] */ "/home/runner/work/xenia/xenia/apps/resident-app/src/app/[locale]/[configId]/components/client-body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorPage"] */ "/home/runner/work/xenia/xenia/apps/resident-app/src/app/[locale]/[configId]/components/error-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/xenia/xenia/apps/resident-app/src/app/[locale]/[configId]/components/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FromResidentApp"] */ "/home/runner/work/xenia/xenia/apps/resident-app/src/app/[locale]/[configId]/from-resident-app.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/xenia/xenia/node_modules/.pnpm/@mui+material@6.4.1_@emotion+react@11.13.0_@emotion+styled@11.13.0_@types+react@19.0.10_react-dom@19.0.0_react@19.0.0/node_modules/@mui/material/CssBaseline/CssBaseline.js");
;
import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/home/runner/work/xenia/xenia/node_modules/.pnpm/next-client-cookies@1.1.1_next@15.2.1_react@19.0.0/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/xenia/xenia/node_modules/.pnpm/next-intl@3.23.1_next@15.2.1_react@19.0.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/xenia/xenia/node_modules/.pnpm/next@15.2.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.51.1_react-dom@19.0.0_react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/[configId]/components/root-layout-error.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
