import { PropsWithChildren } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { enGB } from "date-fns/locale/en-GB";

export type I18nProviderProps = PropsWithChildren;

export function I18nProvider(props: I18nProviderProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      {props.children}
    </LocalizationProvider>
  );
}
