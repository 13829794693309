"use client";

import { useTranslations } from "@xenia-libs/next-i18n";
import { Box } from "@xenia-libs/xenia-ui/layout";
import { Typography } from "@xenia-libs/xenia-ui/typography";

export type ErrorPageProps = {
  code?: number;
  message: string;
};

export function ErrorPage({ code, message }: ErrorPageProps) {
  const t = useTranslations("errors");

  return (
    <Box component="main" pt="20vh" p={2} textAlign="center">
      <Typography variant="h2">{t("error")}</Typography>
      <Typography variant="body1">
        {code != null && `${code} - `}
        {message}
      </Typography>
    </Box>
  );
}
