"use client";

import { PropsWithChildren } from "react";
import { I18nProvider, I18nProviderProps } from "../i18n";
import { ThemeProvider, ThemeProviderProps } from "../theme";
import { ToastProvider, ToastProviderProps } from "../toast";

export type XeniaUiProviderProps = PropsWithChildren<{
  i18nProps?: Omit<I18nProviderProps, "children">;
  themeProps?: Omit<ThemeProviderProps, "children">;
  toastProps?: Omit<ToastProviderProps, "children">;
}>;

export function XeniaUiProvider(props: XeniaUiProviderProps) {
  return (
    <I18nProvider {...props.i18nProps}>
      <ThemeProvider {...props.themeProps}>
        <ToastProvider {...props.toastProps}>{props.children}</ToastProvider>
      </ThemeProvider>
    </I18nProvider>
  );
}
