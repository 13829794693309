"use client";
import { useNavStore } from "@xenia-libs/resident-app-ui/stores/navigation.store";
import { Box } from "@xenia-libs/xenia-ui/layout";
import { PropsWithChildren } from "react";
import { HEADER_HEIGHT } from "../(main)/components/client-navigation-layout";

export function ClientBody({ children }: PropsWithChildren) {
  const isNavOpen = useNavStore((state) => state.isNavOpen);

  return (
    <Box
      component="body"
      sx={{ scrollMarginTop: HEADER_HEIGHT }}
      overflow={isNavOpen ? "hidden" : undefined}
    >
      {children}
    </Box>
  );
}
